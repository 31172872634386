import axios from "axios";

const API_URL = "http://sub.halapack.com/halapack/public";

const register = (username: string, email: string, password: string) => {
  return axios.post(API_URL + "/api/user/users", {
    username,
    email,
    password,
  });
};

const login = (email: string, passwords: string, device_id: string) => {
  return (
    axios
      // .post(
      //   "https://ministrieapidev.metasignapp.com/api/TokenAuth/Authenticate",
      //   {
      //     userNameOrEmailAddress: email,
      //     password: passwords,
      //     rememberClient: true,
      //   }
      // )
      .post("https://ministryapi.metasignapp.com/api/TokenAuth/Authenticate", {
        userNameOrEmailAddress: email,
        password: passwords,
        rememberClient: true,
      })
      .then((response) => {
        if (response.data.result.accessToken) {
          localStorage.setItem("KEY_TOKEN", response.data.result.accessToken);
          localStorage.setItem("user", response.data.result.accessToken);
        }

        return response.data;
      })
  );
};

const logout = () => {
  localStorage.removeItem("KEY_TOKEN");
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
};
