import { AxiosRequestConfig } from "axios";
import ApiService from "../utils/api-services";

class MinistriesService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: `${process.env.REACT_APP_BASE_LOCALY_URL}api`,
      ...config,
    });
  }

  public GetMinistryById = async (id: any): Promise<any> => {
    return await this.get(`/services/app/Ministry/Get?id=${id}`);
  };

  public getMinistriesWithPagination = async (
    selectedValue: number,
    keyword: any,
    SkipCount: any
  ): Promise<any> => {
    return await this.get(
      `/services/app/Ministry/GetAll?MaxResultCount=${selectedValue}&SkipCount=${SkipCount}&Keyword=${keyword}`
    );
  };

  public getMinistriesWithoutPagination = async (): Promise<any> => {
    return await this.get(`/services/app/Ministry/GetAll`);
  };

  public AddMinistry = async (body: any): Promise<any> => {
    return await this.post("/services/app/Ministry/Create", body);
  };

  public EditMinistry = async (body: any): Promise<any> => {
    return await this.put("/services/app/Ministry/Update", body);
  };

  public DeleteMinistry = async (id: any): Promise<any> => {
    return await this.delete(`/services/app/Ministry/Delete?Id=${id}`);
  };
}

export const Ministries = new MinistriesService();
