import { Departments } from "../services/Departments";

export const GetDepartmentById = async (id: any) => {
  const result = await Departments.GetDepartmentById(id);
  return result.result;
};

export const getDepartmentsWithPagination = async (
  selectedValue: number,
  Keyword: any,
  skipcount: any,
  sector_id: number
) => {
  const result = await Departments.getDepartmentsWithPagination(
    selectedValue,
    Keyword,
    skipcount,
    sector_id
  );
  return result.result;
};

export const getDepartmentsWithoutPagination = async () => {
  const result = await Departments.getDepartmentsWithoutPagination();
  return result.result;
};

export const AddDepartment = async (body: any) => {
  const result = await Departments.AddDepartment(body);
  return result;
};

export const EditDepartment = async (body: any) => {
  const result = await Departments.EditDepartment(body);
  return result;
};

export const DeleteDepartment = async (id: any) => {
  const result = await Departments.DeleteDepartment(id);
  return result;
};
