import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { UploadAttachment } from "../services/AttachmentUpload";
import { GetMinistryById } from "../react-query-services/Ministries";
import { GetSectorById } from "../react-query-services/Sectors";
import { GetDepartmentById } from "../react-query-services/Departments";
import {
  GetUserById,
  getUsersWithPagination,
  getLeadersWithPagination,
  getEmployeesWithPagination,
  AddUser,
  EditUser,
  DeleteUser,
} from "../react-query-services/Users";
import {
  Row,
  Col,
  Card,
  Table,
  Upload,
  Form,
  Button,
  Input,
  Drawer,
  notification,
  Radio,
  Popconfirm,
  Pagination,
  Select,
  Image,
  Spin,
} from "antd";
import { useParams, Link } from "react-router-dom";
import { Add_User_Req, Edit_User_Req } from "../enum/user-enum";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { RiDeleteBin5Fill, RiEditFill } from "react-icons/ri";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { UploadOutlined } from "@ant-design/icons";
import GridLoader from "react-spinners/GridLoader";
import avatar from "../assets/images/photo_2023-03-05_12-55-29.jpg";
import { useMediaQuery } from "react-responsive";
import "../assets/styles/Drawer.css";
import "../assets/styles/Dropdown.css";

interface Iparam {
  id: string;
  idSector: string;
  idDepartment: string;
  userType: string;
}

function Users(props: any) {
  let id: Iparam = useParams();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [idUser, setIdUser] = useState<number>(0);
  const [skipcount, SetSkipCount] = useState<number>(0);
  const [totalCount, SetTotalCount] = useState<any>(1);
  const [selectedValue, setSelectedValue] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [userType, setUserType] = useState(0);
  const [title, setTitle] = useState(
    +id.userType && !+id.idSector
      ? "Interactive"
      : +id.userType && +id.idSector
      ? "Leaders"
      : ""
  );
  const [head, setHead] = useState(" ");
  const [showTableTitle, setShowTableTitle] = useState(false);
  const [afterEdit, setAfterEdit] = useState(false);
  const [isUploadChanged, setUploadChanged] = useState<boolean>(false);

  const pageSizeOptions = ["10", "20", "30"];
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // get all users types (Interactive, Private Room, Call Manager, Main Unit, Leaders, Sector Employees, Department Employee) by id
  const GetOneUser = useQuery({
    queryKey: ["GetUserById", idUser],
    queryFn: () => GetUserById(idUser),
    enabled: !afterEdit,
  });

  // ministry
  const GetOneMinistry = useQuery({
    queryKey: ["GetMinistryById", id.id],
    queryFn: () => GetMinistryById(id.id),
    enabled: +id.id != 0 && id.id != undefined,
  });

  // sector
  const GetOneSector = useQuery({
    queryKey: ["GetSectorById", id.idSector],
    queryFn: () => GetSectorById(id.idSector),
    enabled: +id.idSector != 0 && id.idSector != undefined,
  });

  // unit
  const GetOneDepartment = useQuery({
    queryKey: ["GetDepartmentById", id.idDepartment],
    queryFn: () => GetDepartmentById(id.idDepartment),
    enabled: +id.idDepartment != 0 && id.idDepartment != undefined,
  });

  useEffect(() => {
    if (isUploadChanged) {
    } else {
      form2.resetFields();
    }
  });

  // to get the users(Interactive, Private Room, Call Manager, Main Unit) in the ministry
  const { isLoading, isError, error, data, isFetching, refetch } = useQuery({
    queryKey: [
      "GetAllUsers",
      selectedValue,
      props.Search,
      skipcount,
      userType,
      id.id,
    ],
    queryFn: () =>
      getUsersWithPagination(
        selectedValue,
        props.Search,
        skipcount,
        userType,
        id.id
      ),
  });

  // to get the users(leaders, Sector Employees) in the sector
  const {
    isLoading: isLoadingLeaders,
    isError: isErrorLeaders,
    error: errorLeaders,
    data: dataLeaders,
    isFetching: isFetchingLeaders,
    refetch: refetchLeaders,
  } = useQuery({
    queryKey: [
      "getLeadersWithPagination",
      selectedValue,
      props.Search,
      skipcount,
      userType,
      id.idSector,
    ],
    queryFn: () =>
      getLeadersWithPagination(
        selectedValue,
        props.Search,
        skipcount,
        userType,
        id.idSector
      ),
    enabled: !!id.idSector,
  });

  // to get the employees in the department
  const {
    isLoading: isLoadingEmployee,
    isError: isErrorEmployee,
    error: errorEmployee,
    data: dataEmployee,
    isFetching: isFetchingEmployee,
    refetch: refetchEmployee,
  } = useQuery({
    queryKey: [
      "getEmployeesWithPagination",
      selectedValue,
      props.Search,
      skipcount,
      id.idDepartment,
    ],
    queryFn: () =>
      getEmployeesWithPagination(
        selectedValue,
        props.Search,
        skipcount,
        id.idDepartment
      ),
    enabled: id.idDepartment ? true : false,
  });

  const onChange1 = (e: any) => {
    if (e.target.value === "a") {
      setTitle("Interactive");
    } else if (e.target.value === "b") {
      setTitle("Private Room");
    } else if (e.target.value === "c") {
      setTitle("Call Manager");
    } else if (e.target.value === "d") {
      setTitle("Main Unit");
    }
  };

  const onChange2 = (e: any) => {
    if (e.target.value === "e") {
      setTitle("Leaders");
    } else if (e.target.value === "f") {
      setTitle("Sector Employees");
    }
  };

  useEffect(() => {
    if (id.userType === "3") {
      setTitle("Waiting Screen");
    }
    setShowTableTitle(false);
  }, [id.userType]);

  useEffect(() => {
    if (title === "Interactive") {
      setUserType(1);
      setHead(GetOneMinistry?.data?.name);
    }
    if (title === "Private Room") {
      setUserType(4);
      setHead(GetOneMinistry?.data?.name);
    }
    if (title === "Call Manager") {
      setUserType(5);
      setHead(GetOneMinistry?.data?.name);
    }
    if (title === "Main Unit") {
      setUserType(6);
      setHead(GetOneMinistry?.data?.name);
    }
    if (title === "Leaders") {
      setUserType(2);
      setHead(GetOneSector?.data?.name);
    }
    if (title === "Sector Employees") {
      setUserType(7);
      setHead(GetOneSector?.data?.name);
    }
    if (title === "Waiting Screen") {
      setUserType(3);
      setHead(GetOneDepartment?.data?.name);
    }
    setShowTableTitle(true);
  }, [title, GetOneMinistry, GetOneSector, GetOneDepartment]);

  useEffect(() => {
    form1.resetFields();
  }, [title]);

  const AddNewUser = useMutation({
    mutationKey: ["AddNewUser"],
    mutationFn: AddUser,
    onSuccess: (res: any) => {
      if (res.error) {
        notification.error({
          message: res?.error?.message,
        });
        return;
      }
      notification.success({
        message: "The User Added Successfuly",
      });
      refetch();
      refetchLeaders();
      refetchEmployee();
      form1.resetFields();
      setAddVisible(false);
    },
    onError: (res: any) => {
      notification.error({
        message: res?.error?.message,
      });
    },
  });

  const EditTheUser = useMutation({
    mutationKey: ["EditUser"],
    mutationFn: EditUser,
    onSuccess: (res: any) => {
      if (res.error) {
        notification.error({
          message: res?.error?.message,
        });
        return;
      }
      setAfterEdit(true);
      notification.success({
        message: "The User Updated Successfuly",
      });
      form2.resetFields();
      refetch();
      refetchLeaders();
      refetchEmployee();
      setEditVisible(false);
      setAfterEdit(false);
      setUploadChanged(false);
    },
    onError: (res: any) => {
      notification.error({
        message: res?.error?.message,
      });
    },
  });

  const DeletedHandler = (id: any) => {
    DeleteUser(id).then((res) => {
      if (res?.result == null && res.error == null) {
        notification.success({
          message: "The User Deleted Successfully",
        });
        refetch();
        refetchLeaders();
        refetchEmployee();
      }
      if (res?.result == null && res.error != null) {
        notification.error({
          message: res?.error?.message,
        });
      }
    });
  };

  const confirm = (id: any) => {
    DeletedHandler(id);
  };

  const showAddDrawer = () => setAddVisible(true);
  const showEditDrawer = (id: any) => {
    setIdUser(id);
    setEditVisible(true);
  };

  const columns = [
    id.userType === "2" && userType === 2
      ? {
          title: (
            <p style={{ marginBottom: 0, textAlign: "center" }}>Attachments</p>
          ),
          dataIndex: "attachment",
          key: "attachment",
          width: "20%",
          render: (attachment: any) => {
            return (
              <Image
                src={attachment?.url || avatar}
                alt=""
                width={100}
                height={100}
                preview={false}
                style={{
                  borderRadius: "12px",
                  padding: 4,
                }}
                className="table-images"
              ></Image>
            );
          },
        }
      : {},
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>FullName</p>,
      dataIndex: "fullName",
      key: "fullName",
      width: "20%",
      render: (fullName: any, id: any) => {
        return (
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: 0,
            }}
          >
            {fullName + ""}
          </p>
        );
      },
    },
    id.userType === "2" && userType === 2
      ? {
          title: (
            <p style={{ marginBottom: 0, textAlign: "center" }}>UserPosition</p>
          ),
          dataIndex: "userPosition",
          key: "userPosition",
          width: "20%",
          render: (userPosition: any, id: any) => {
            return (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: 0,
                }}
              >
                {userPosition + ""}
              </p>
            );
          },
        }
      : {},
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>UserName</p>,
      dataIndex: "userName",
      key: "userame",
      width: "20%",
      render: (username: any, id: any) => {
        return (
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: 0,
            }}
          >
            {username + ""}
          </p>
        );
      },
    },
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>Actions</p>,
      key: "actions",
      dataIndex: "id",
      width: "20%",
      render: (id: any, body: any) => {
        return (
          <Row
            justify="center"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <Popconfirm
              placement="top"
              title={"Are you sure to delete this User?"}
              onConfirm={() => confirm(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                ghost
                style={{
                  padding: "0px 8px",
                  margin: "auto 5px",
                  height: "30px",
                }}
              >
                <RiDeleteBin5Fill
                  style={{
                    fontSize: "small",
                    margin: "auto",
                    marginBottom: "15px",
                  }}
                />
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              ghost
              onClick={() => showEditDrawer(id)}
              style={{
                padding: "0px 8px",
                margin: "auto 5px",
                height: "30px",
              }}
            >
              <RiEditFill
                style={{
                  fontSize: "small",
                  margin: "auto",
                  marginBottom: "15px",
                }}
              />
            </Button>
          </Row>
        );
      },
    },
  ];

  const AddHandler = (e: any) => {
    let body: Add_User_Req = {
      name: "",
      surname: "",
      emailAddress: "",
      password: "",
      userName: "",
      ministryId: 0,
      departmentId: 0,
      unitId: 0,
      userType: 0,
      userPosition: "",
      attachmentId: null,
      isActive: true,
    };
    body.name = e.name;
    body.surname = e.surname;
    body.emailAddress = e.userName;
    body.password = e.password;
    body.userName = e.userName;
    body.userType = userType;
    body.isActive = true;
    if (
      +id.userType === 1 ||
      +id.userType === 4 ||
      +id.userType === 5 ||
      +id.userType === 6
    ) {
      body.departmentId = null;
      body.unitId = null;
      body.ministryId = +id.id;
      body.userPosition = null;
    } else if (+id.userType === 2 || +id.userType === 7) {
      body.departmentId = +id.idSector;
      body.unitId = null;
      body.ministryId = null;
      body.userPosition = e.userPosition;
    } else if (+id.userType === 3) {
      body.departmentId = null;
      body.unitId = +id.idDepartment;
      body.ministryId = null;
      body.userPosition = null;
    }
    let formData = new FormData();
    formData.append("RefType", 3 + " ");
    formData.append("File", e?.image1?.file);
    if (+id.userType === 2 && e?.image1?.file) {
      UploadAttachment.UploadAttachment(formData).then((res: any) => {
        body.attachmentId = res?.result?.id as number;
        AddNewUser.mutateAsync(body);
      });
    } else {
      AddNewUser.mutateAsync(body);
    }
  };

  const editHandler = (e: any) => {
    let body: Edit_User_Req = {
      name: "",
      surname: "",
      emailAddress: "",
      password: "",
      userName: "",
      ministryId: 0,
      departmentId: 0,
      unitId: 0,
      userType: 0,
      userPosition: "",
      attachmentId: null,
      isActive: true,
      id: 0,
    };
    body.name = e.name;
    body.surname = e.surname;
    body.emailAddress = e.userName;
    body.password = e.password;
    body.userName = e.userName;
    body.userType = +userType;
    body.isActive = true;
    body.id = idUser;
    if (+id.userType === 2 || +id.userType === 7) {
      body.departmentId = +id.idSector;
      body.unitId = null;
      body.ministryId = null;
      body.userPosition = e.userPosition;
    } else if (+id.userType === 3) {
      body.departmentId = null;
      body.unitId = +id.idDepartment;
      body.ministryId = null;
      body.userPosition = null;
    } else {
      body.departmentId = null;
      body.unitId = null;
      body.ministryId = +id.id;
      body.userPosition = null;
    }
    let formData = new FormData();
    formData.append("RefType", 3 + " ");
    formData.append("File", e?.image2?.file);
    if (+id.userType === 2) {
      if (GetOneUser.data.attachment) {
        if (e?.image2?.file) {
          UploadAttachment.UploadAttachment(formData).then((res: any) => {
            body.attachmentId = res?.result?.id as number;
            EditTheUser.mutateAsync(body);
          });
        } else {
          body.attachmentId = GetOneUser.data.attachment.id;
          EditTheUser.mutateAsync(body);
        }
      } else {
        if (e?.image2?.file) {
          UploadAttachment.UploadAttachment(formData).then((res: any) => {
            body.attachmentId = res?.result?.id as number;
            EditTheUser.mutateAsync(body);
          });
        } else {
          body.attachmentId = null;
          EditTheUser.mutateAsync(body);
        }
      }
    } else if (+id.userType === 1 || 3 || 4 || 5 || 6 || 7) {
      EditTheUser.mutateAsync(body);
    }
  };

  useEffect(() => {
    if (id.userType === "1")
      if (totalCount < data?.totalCount || totalCount > data?.totalCount) {
        SetTotalCount(data?.totalCount);
      }
    if (id.userType === "2")
      if (
        totalCount < dataLeaders?.totalCount ||
        totalCount > dataLeaders?.totalCount
      ) {
        SetTotalCount(dataLeaders?.totalCount);
      }
    if (id.userType === "3")
      if (
        totalCount < dataEmployee?.totalCount ||
        totalCount > dataEmployee?.totalCount
      ) {
        SetTotalCount(dataEmployee?.totalCount);
      }
  }, [data, dataLeaders, dataEmployee, totalCount]);

  function handlePageSizeChange(value: any) {
    setSelectedValue(value);
  }
  const pageSize = selectedValue;

  function handleKeyDown(event: any) {
    if (event.key === "ArrowLeft") {
      setSkipCount(skipCount - pageSize);
    } else if (event.key === "ArrowRight") {
      setSkipCount(skipCount + pageSize);
    }
  }

  return (
    <>
      <div className="setting-drwer" onClick={showAddDrawer}>
        <AiOutlineAppstoreAdd />
      </div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24} style={{ width: "100%" }}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col>
                    <Link
                      to={
                        id.userType === "1"
                          ? "/ministries"
                          : id.userType === "2"
                          ? `/ministry/${id.id}/sector/`
                          : id.userType === "3"
                          ? `/ministry/${id.id}/sector/${id.idSector}/department/`
                          : "dashboard"
                      }
                    >
                      <IoReturnUpBackOutline
                        style={{
                          marginRight: 8,
                          cursor: "pointer",
                          color: "rgba(0, 0, 0, 0.65)",
                          fontSize: "x-large",
                        }}
                      />
                    </Link>
                  </Col>
                  {showTableTitle && (
                    <Col>
                      {head === undefined ? (
                        <>
                          {title + " Accounts of: "} <Spin spinning={true} />
                        </>
                      ) : (
                        title + " Accounts of: " + head
                      )}
                    </Col>
                  )}
                  <Col></Col>
                </Row>
              }
              extra={
                id.userType === "1" ? (
                  <>
                    <Radio.Group onChange={onChange1} defaultValue="a">
                      <Radio.Button value="a">Interactive</Radio.Button>
                      <Radio.Button value="b">Private Room</Radio.Button>
                      <Radio.Button value="c">Call Manager</Radio.Button>
                      <Radio.Button value="d">Main Unit</Radio.Button>
                    </Radio.Group>
                  </>
                ) : (
                  id.userType === "2" && (
                    <>
                      <Radio.Group onChange={onChange2} defaultValue="e">
                        <Radio.Button value="e">Leaders</Radio.Button>
                        <Radio.Button value="f">Sector Employees</Radio.Button>
                      </Radio.Group>
                    </>
                  )
                )
              }
            >
              {(isLoading || isLoadingLeaders || isLoadingEmployee) && (
                <GridLoader
                  color="#36d7b7"
                  loading={
                    id.userType === "1" ||
                    id.userType === "4" ||
                    id.userType === "5" ||
                    id.userType === "6"
                      ? isLoading
                      : id.userType === "2" || id.userType === "7"
                      ? isLoadingLeaders
                      : isLoadingEmployee
                  }
                  size={10}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignContent: "space-around",
                    flexWrap: "wrap",
                    margin: "20% auto",
                  }}
                />
              )}
              {id.userType === "1" && !isLoading && (
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={data?.items}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              )}
              {id.userType === "2" && !isLoadingLeaders && (
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={dataLeaders?.items}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              )}
              {id.userType === "3" && !isLoadingEmployee && (
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={dataEmployee?.items}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              )}
              <Row
                style={{
                  width: "100%",
                  justifyContent: "center",
                  margin: "20px auto",
                }}
              >
                <Row tabIndex={0} onKeyDown={handleKeyDown}>
                  <Col>
                    <Pagination
                      current={skipcount / selectedValue + 1}
                      total={totalCount}
                      pageSize={selectedValue}
                      onChange={(page) => {
                        SetSkipCount((page - 1) * selectedValue);
                      }}
                    />
                  </Col>
                  <Col style={{ marginLeft: "20px" }}>
                    <Select
                      defaultValue={pageSizeOptions[0]}
                      onChange={handlePageSizeChange}
                    >
                      {pageSizeOptions.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <Drawer
        width={isMobile ? 300 : 500}
        open={addVisible}
        title="Add New User"
        onClose={() => setAddVisible(false)}
        placement="right"
      >
        <Form form={form1} name="basic1" onFinish={AddHandler}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"Name"}
              name="name"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "The Name is Required ",
                },
              ]}
            >
              <Input placeholder="Name"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"SurName"}
              name="surname"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "The SurName is Required ",
                },
              ]}
            >
              <Input placeholder="SurName"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"UserName"}
              name="userName"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "the UserName is Required ",
                },
              ]}
            >
              <Input placeholder="UserName"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"Password"}
              name="password"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "The Password is Required ",
                },
              ]}
            >
              <Input placeholder="Password"></Input>
            </Form.Item>
          </Col>
          {id.userType === "2" && userType === 2 && (
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label={"UserPosition"}
                name="userPosition"
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "The UserPosition is Required ",
                  },
                ]}
              >
                <Input placeholder="UserPosition" type="text"></Input>
              </Form.Item>
            </Col>
          )}
          {id.userType === "2" && userType === 2 && (
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label={"image"}
                name="image1"
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: false,
                    message: "the User Image is Required ",
                  },
                ]}
              >
                <Upload
                  listType="picture"
                  className="upload-list-inline"
                  beforeUpload={() => false}
                  maxCount={1}
                >
                  <div
                    style={{
                      color: "#0388cc",
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
          )}
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Drawer>
      <Drawer
        width={isMobile ? 300 : 500}
        open={editVisible}
        title={`Edit The ${title} Info`}
        onClose={() => {
          setEditVisible(false);
        }}
        placement="right"
      >
        <GridLoader
          color="#36d7b7"
          loading={!GetOneUser.data}
          size={10}
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignContent: "space-around",
            flexWrap: "wrap",
            margin: "auto",
            marginTop: "60%",
          }}
        />
        {GetOneUser.status === "success" && GetOneUser.data && (
          <Form
            key={idUser}
            form={form2}
            name="basic2"
            autoComplete="off"
            onFinish={editHandler}
            initialValues={{
              ...GetOneUser.data,
            }}
          >
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label={"Name"}
                name="name"
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "The Name is Required ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label={"SurName"}
                name="surname"
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "The SurName is Required ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label={"UserName"}
                name="userName"
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "The UserName is Required ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {id.userType === "2" && userType === 2 && (
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label={"UserPosition"}
                  name="userPosition"
                  labelCol={{ span: 24 }}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "The UserPosition is Required ",
                    },
                  ]}
                >
                  <Input placeholder="UserPosition"></Input>
                </Form.Item>
              </Col>
            )}
            {id.userType === "2" && userType === 2 && (
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label="image"
                  name="image2"
                  labelCol={{ span: 24 }}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: false,
                      message: "the Ministry Image is Required ",
                    },
                  ]}
                >
                  <Upload
                    listType="picture"
                    className="upload-list-inline"
                    beforeUpload={() => false}
                    maxCount={1}
                    onChange={() => setUploadChanged(true)}
                    defaultFileList={
                      GetOneUser?.data?.attachment
                        ? [
                            {
                              uid: GetOneUser?.data?.attachment.id,
                              name: "attachment",
                              url: GetOneUser?.data?.attachment.url,
                              thumbUrl: GetOneUser?.data?.attachment.url,
                            },
                          ]
                        : []
                    }
                  >
                    <div
                      style={{
                        color: "#0388cc",
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        onClick={() => {
                          setUploadChanged(true);
                        }}
                      >
                        Upload
                      </Button>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
            )}
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Form>
        )}
      </Drawer>
    </>
  );
}

export default Users;
