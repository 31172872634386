import { Sectors } from "../services/Sectors";

export const GetSectorById = async (id: any) => {
  const result = await Sectors.GetSectorById(id);
  return result.result;
};

export const getSectorsWithPagination = async (
  selectedValue: number,
  Keyword: any,
  skipcount: any,
  ministry_id: number
) => {
  const result = await Sectors.getSectorsWithPagination(
    selectedValue,
    Keyword,
    skipcount,
    ministry_id
  );
  return result.result;
};

export const getSectorsWithoutPagination = async (ministry_id: number) => {
  const result = await Sectors.getSectorsWithoutPagination(ministry_id);
  return result.result;
};

export const AddSector = async (body: any) => {
  const result = await Sectors.AddSector(body);
  return result;
};

export const EditSector = async (body: any) => {
  const result = await Sectors.EditSector(body);
  return result;
};

export const DeleteSector = async (id: any) => {
  const result = await Sectors.DeleteSector(id);
  return result;
};
