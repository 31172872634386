import { AxiosRequestConfig } from "axios";
import ApiService from "../utils/api-services";

//!  the Departments here are sectors
//!  the Departments here are sectors
//!  the Departments here are sectors

class SectorsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: `${process.env.REACT_APP_BASE_LOCALY_URL}api`,
      ...config,
    });
  }

  public GetSectorById = async (id: any): Promise<any> => {
    return await this.get(`/services/app/Department/Get?Id=${id}`);
  };

  public getSectorsWithPagination = async (
    selectedValue: number,
    keyword: any,
    SkipCount: any,
    ministry_id: number
  ): Promise<any> => {
    return await this.get(
      `/services/app/Department/GetAll?MaxResultCount=${selectedValue}&SkipCount=${SkipCount}&Keyword=${keyword}&MinistryId=${ministry_id}`
    );
  };

  public getSectorsWithoutPagination = async (
    ministry_id: number
  ): Promise<any> => {
    return await this.get(
      `/services/app/Department/GetAll?MinistryId=${ministry_id}`
    );
  };

  public AddSector = async (body: any): Promise<any> => {
    return await this.post("/services/app/Department/Create", body);
  };

  public EditSector = async (body: any): Promise<any> => {
    return await this.put("/services/app/Department/Update", body);
  };

  public DeleteSector = async (id: any): Promise<any> => {
    return await this.delete(`/services/app/Department/Delete?Id=${id}`);
  };
}

export const Sectors = new SectorsService();
