import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Layout } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }: any) {
  const [sidenavType, setSidenavType] = useState("transparent");
  const history = useHistory();

  const isLoggedIn = useSelector(
    (state: RootState) => state.auth.auth.isLoggedIn
  );

  useEffect(() => {
    if (!isLoggedIn) {
      history.push("/login");
    }
  }, [isLoggedIn]);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  return (
    <Layout
      className={`layout-dashboard ${
        pathname === "profile" ? "layout-profile" : ""
      } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        width={220}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sidenavType }}
      >
        <Sidenav />
      </Sider>
      <Layout>
        <AntHeader>
          <Header />
        </AntHeader>

        <Content className="content-ant">{children}</Content>
      </Layout>
    </Layout>
  );
}

export default Main;
