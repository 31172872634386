import { FC, useState, useEffect } from "react";
import { Layout, Button, Typography, Row, Col, Form, Input } from "antd";
import { login } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { useHistory } from "react-router-dom";
import logo from "../assets/images/photo_2023-03-05_13-29-50.jpg";

const { Title } = Typography;
const { Header, Content } = Layout;

const Login: FC = () => {
  const history = useHistory();
  const isLoggedIn = useSelector(
    (state: RootState) => state.auth.auth.isLoggedIn
  );
  const loginStatus = useSelector((state: RootState) => state.auth.auth.status);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const dispatch = useDispatch();

  const loginSubmit = () => {
    setLoadingState(true);
    dispatch<any>(login(email, password, "loooginnn"));
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/ministries");
    } else {
      setLoadingState(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (loginStatus === "fail") {
      setLoadingState(false);
    }
  }, [loginStatus]);

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand">
            <h5>Ministries Dashboard</h5>
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
              style={{ paddingTop: "80px" }}
            >
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to sign in
              </Title>
              <Form
                onFinish={loginSubmit}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Password"
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={logo} alt="" />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Login;
