import { Users } from "../services/Users";

export const GetUserById = async (id: any) => {
  const result = await Users.GetUserById(id);
  return result.result;
};

export const getUsersWithPagination = async (
  selectedValue: number,
  Keyword: any,
  skipcount: any,
  UserType: any,
  MinistryId: any
) => {
  const result = await Users.getUsersWithPagination(
    selectedValue,
    Keyword,
    skipcount,
    UserType,
    MinistryId
  );
  return result.result;
};

export const getLeadersWithPagination = async (
  selectedValue: number,
  Keyword: any,
  skipcount: any,
  UserType: any,
  DepartmentId: any
) => {
  const result = await Users.getLeadersWithPagination(
    selectedValue,
    Keyword,
    skipcount,
    UserType,
    DepartmentId
  );
  return result.result;
};

export const getEmployeesWithPagination = async (
  selectedValue: number,
  Keyword: any,
  skipcount: any,
  UnitId: any
) => {
  const result = await Users.getEmployeesWithPagination(
    selectedValue,
    Keyword,
    skipcount,
    UnitId
  );
  return result.result;
};

export const getUsersWithoutPagination = async () => {
  const result = await Users.getUsersWithoutPagination();
  return result.result;
};

export const AddUser = async (body: any) => {
  const result = await Users.AddUser(body);
  return result;
};

export const EditUser = async (body: any) => {
  const result = await Users.EditUser(body);
  return result;
};

export const DeleteUser = async (id: any) => {
  const result = await Users.DeleteUser(id);
  return result;
};
