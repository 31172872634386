import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { GetMinistryById } from "../react-query-services/Ministries";
import {
  getClientRequestsForExport,
  getClientRequestsWithPagination,
} from "../react-query-services/ClientRequest";
import {
  Row,
  Col,
  Card,
  Table,
  Tag,
  Pagination,
  Select,
  Modal,
  DatePicker,
  Button,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import { IoReturnUpBackOutline } from "react-icons/io5";
import GridLoader from "react-spinners/GridLoader";
import "../assets/styles/Drawer.css";
import "../assets/styles/Dropdown.css";
import { FilterOutlined } from "@ant-design/icons";
import moment from "moment";

interface Iparam {
  id: string;
}

function ClientRequest(props: any) {
  let id: Iparam = useParams();
  const pageSizeOptions = ["10", "20", "30"];

  const [skipcount, SetSkipCount] = useState<number>(0);
  const [totalCount, SetTotalCount] = useState<any>(1);
  const [selectedValue, setSelectedValue] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState<string | null>(null);
  const [selectedToDate, setSelectedToDate] = useState<string | null>(null);
  const [refetchOnOK, setRefetchOnOK] = useState<boolean>(true);

  const GetOneMinistry = useQuery({
    queryKey: ["GetMinistryById", id.id],
    queryFn: () => GetMinistryById(id.id),
  });

  const { isLoading, isError, error, data, isFetching, refetch } = useQuery({
    queryKey: [
      "getClientRequestsWithPagination",
      selectedValue,
      props.Search,
      skipcount,
      id.id,
    ],
    queryFn: () =>
      getClientRequestsWithPagination(
        selectedValue,
        props.Search,
        skipcount,
        +id.id,
        selectedFromDate,
        selectedToDate
      ),
    enabled: refetchOnOK,
  });

  const exportToExcel = async () => {
    try {
      const file = await getClientRequestsForExport(
        props.Search,
        skipcount,
        +id.id,
        selectedFromDate,
        selectedToDate
      );

      const url = URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ClientRequests.xlsx");
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);

      setSelectedFromDate(null);
      setSelectedToDate(null);
    } catch (error) {
      console.error("Error Exporting Excel:", error);
    }
  };

  const columns = [
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>Order Number</p>
      ),
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: "22%",
      render: (orderNumber: any, id: any) => {
        return (
          <p
            style={{
              maxWidth: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {orderNumber + ""}
          </p>
        );
      },
    },
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>Name</p>,
      dataIndex: "clientFullName",
      key: "clientFullName",
      width: "22%",
      render: (clientFullName: any, id: any) => {
        return (
          <p
            style={{
              maxWidth: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {clientFullName + ""}
          </p>
        );
      },
    },
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>Unit Name</p>,
      dataIndex: "unit",
      key: "unit",
      width: "20%",
      render: (unit: any) => {
        return (
          <p
            style={{
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {unit?.name}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>
          Transaction Number
        </p>
      ),
      dataIndex: "transactionNumber",
      key: "transactionNumber",
      width: "20%",
      render: (transactionNumber: any) => {
        return (
          <p
            style={{
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {transactionNumber == null ? "___" : transactionNumber}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>Creation Date</p>
      ),
      dataIndex: "creationTime",
      key: "creationTime",
      width: "40%",
      render: (creationTime: any) => {
        let date = new Date(creationTime);
        return (
          <p
            style={{
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {date.getFullYear() +
              "/" +
              `${date.getUTCMonth() + 1}` +
              "/" +
              date.getDate()}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>Creation Time</p>
      ),
      dataIndex: "creationTime",
      key: "creationTime",
      width: "40%",
      render: (creationTime: any) => {
        let date = new Date(creationTime);
        return (
          <p
            style={{
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {date.getHours() +
              ":" +
              date.getMinutes() +
              ":" +
              date.getSeconds()}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>
          Client Request Type
        </p>
      ),
      dataIndex: "clientRequestType",
      key: "clientRequestType",
      width: "20%",
      render: (clientRequestType: any) => {
        return (
          <Tag
            style={{
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
            color={clientRequestType == 1 ? "#25af2e" : "#1890ff"}
          >
            {clientRequestType == 1 ? "Checking" : "Done"}
          </Tag>
        );
      },
    },
  ];

  useEffect(() => {
    if (totalCount < data?.totalCount || totalCount > data?.totalCount) {
      SetTotalCount(data?.totalCount);
    }
  }, [data, totalCount]);

  function handlePageSizeChange(value: any) {
    setSelectedValue(value);
  }
  const pageSize = selectedValue;

  function handleKeyDown(event: any) {
    if (event.key === "ArrowLeft") {
      setSkipCount(skipCount - pageSize);
    } else if (event.key === "ArrowRight") {
      setSkipCount(skipCount + pageSize);
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
    setRefetchOnOK(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setRefetchOnOK(true);
    refetch();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const text = <span>Please Filter On Date First!</span>;

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24} style={{ width: "100%" }}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col>
                    <Link to="/ministries">
                      <IoReturnUpBackOutline
                        style={{
                          marginRight: 8,
                          cursor: "pointer",
                          color: "rgba(0, 0, 0, 0.65)",
                          fontSize: "x-large",
                        }}
                      />
                    </Link>
                  </Col>
                  {!isFetching && (
                    <Col>
                      Client Requests for : {GetOneMinistry?.data?.name}
                    </Col>
                  )}
                  <Col>
                    <h5
                      onClick={showModal}
                      style={{ marginBottom: "0", cursor: "pointer" }}
                    >
                      Filter on Date <FilterOutlined />
                    </h5>
                    <Tooltip placement="bottom" title={text}>
                      <Button
                        disabled={!selectedFromDate}
                        onClick={exportToExcel}
                      >
                        Export To Excel
                      </Button>
                    </Tooltip>
                  </Col>
                  <Modal
                    title="Filter by Date"
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <DatePicker
                      onChange={(date, dateString) =>
                        setSelectedFromDate(dateString)
                      }
                      value={selectedFromDate ? moment(selectedFromDate) : null}
                    />
                    <DatePicker
                      onChange={(date, dateString) =>
                        setSelectedToDate(dateString)
                      }
                      value={selectedToDate ? moment(selectedToDate) : null}
                    />
                  </Modal>
                </Row>
              }
            >
              <GridLoader
                color="#36d7b7"
                loading={isFetching}
                size={10}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignContent: "space-around",
                  flexWrap: "wrap",
                  margin: "20% auto",
                }}
              />
              {!isFetching && (
                <div className="table-responsive">
                  <Table
                    columns={data ? columns : []}
                    dataSource={data?.items}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              )}
              <Row
                style={{
                  width: "100%",
                  justifyContent: "center",
                  margin: "20px auto",
                }}
              >
                <Row tabIndex={0} onKeyDown={handleKeyDown}>
                  <Col>
                    <Pagination
                      current={skipcount / selectedValue + 1}
                      total={totalCount}
                      pageSize={selectedValue}
                      onChange={(page) => {
                        SetSkipCount((page - 1) * selectedValue);
                      }}
                    />
                  </Col>
                  <Col style={{ marginLeft: "20px" }}>
                    <Select
                      defaultValue={pageSizeOptions[0]}
                      onChange={handlePageSizeChange}
                    >
                      {pageSizeOptions.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ClientRequest;
