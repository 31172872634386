import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  GetMinistryById,
  getMinistriesWithPagination,
  AddMinistry,
  EditMinistry,
  DeleteMinistry,
} from "../react-query-services/Ministries";
import {
  Row,
  Col,
  Card,
  Table,
  Upload,
  Form,
  Button,
  Input,
  Drawer,
  notification,
  Image,
  Popconfirm,
  Pagination,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import { UploadAttachment } from "../services/AttachmentUpload";
import {
  Translation,
  Add_Ministry_Req,
  Edit_Ministry_Req,
} from "../enum/ministry-enum";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { RiDeleteBin5Fill, RiEditFill } from "react-icons/ri";
import { UploadOutlined } from "@ant-design/icons";
import GridLoader from "react-spinners/GridLoader";
import { useMediaQuery } from "react-responsive";
import "../assets/styles/Drawer.css";
import "../assets/styles/Dropdown.css";

function Ministries(props: any) {
  const { TextArea } = Input;
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [idMinistry, setIdMinistry] = useState<number>(0);
  const [skipcount, SetSkipCount] = useState<number>(0);
  const [totalCount, SetTotalCount] = useState<any>(1);
  const [selectedValue, setSelectedValue] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [typeMinistry, setTypeMinistry] = useState(0);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const pageSizeOptions = ["10", "20", "30"];
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const GetOneMinistry = useQuery({
    queryKey: ["GetMinistryById", idMinistry],
    queryFn: () => GetMinistryById(idMinistry),
    enabled: idMinistry !== 0,
  });

  useEffect(() => {
    if (idMinistry !== 0) {
      form2.resetFields();
      GetOneMinistry.refetch();
    }
  }, [idMinistry, editVisible]);

  const { isLoading, isError, error, data, isFetching, refetch } = useQuery({
    queryKey: ["GetAllMinistries", selectedValue, props.Search, skipcount],
    queryFn: () =>
      getMinistriesWithPagination(selectedValue, props.Search, skipcount),
  });

  const AddNewMinistry = useMutation({
    mutationKey: ["AddNewMinistry"],
    mutationFn: AddMinistry,
    onSuccess: (res: any) => {
      if (res.error) {
        notification.error({
          message: res?.error?.message,
        });
        return;
      }
      notification.success({
        message: "The Ministry Added Successfuly",
      });
      refetch();
      form1.resetFields();
      setAddVisible(false);
      setDisabledSubmit(false);
    },
    onError: (res: any) => {
      notification.error({
        message: res?.error?.message,
      });
    },
  });

  const EditTheMinistry = useMutation({
    mutationKey: ["EditMinistry"],
    mutationFn: EditMinistry,
    onSuccess: (res: any) => {
      if (res.error) {
        notification.error({
          message: res?.error?.message,
        });
        return;
      }
      notification.success({
        message: "The Ministry Updated Successfuly",
      });
      form2.resetFields();
      refetch();
      setEditVisible(false);
    },
    onError: (res: any) => {
      notification.error({
        message: res?.error?.message,
      });
    },
  });

  const DeletedHandler = (id: any) => {
    DeleteMinistry(id).then((res) => {
      if (res?.result == null && res.error == null) {
        notification.success({
          message: " The Ministry Deleted Successfully",
        });
        refetch();
      }
      if (res?.result == null && res.error != null) {
        notification.error({
          message: res?.error?.message,
        });
      }
    });
  };

  const confirm = (id: any) => {
    DeletedHandler(id);
  };

  const showAddDrawer = () => setAddVisible(true);
  const showEditDrawer = (id: any) => {
    setEditVisible(true);
    setIdMinistry(id);
  };

  const columns = [
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>Attachments</p>
      ),
      dataIndex: "attachment",
      key: "attachment",
      width: "20%",
      render: (attachment: any) => {
        return (
          <Image
            src={attachment?.url || "../assets/22.jpg"}
            alt=""
            width={100}
            height={100}
            preview={false}
            style={{
              borderRadius: "12px",
              padding: 4,
            }}
            className="table-images"
          ></Image>
        );
      },
    },
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>Name</p>,
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (name: any, id: any) => {
        return (
          <p
            style={{
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
            }}
          >
            {name + ""}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>Description</p>
      ),
      dataIndex: "description",
      key: "description",
      width: "20%",
      render: (description: any) => {
        return (
          <p
            style={{
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {description + ""}
          </p>
        );
      },
    },
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>Sectors</p>,
      dataIndex: "id",
      key: "id",
      width: "15%",
      render: (id: any) => (
        <Link
          to={`ministry/${id}/sector/`}
          style={{ color: "#777", textAlign: "center" }}
        >
          <Button type="link" className="darkbtn">
            SHOW SECTORS
          </Button>
        </Link>
      ),
    },
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>Accounts</p>,
      dataIndex: "id",
      key: "id",
      width: "15%",
      render: (id: any) => (
        <Link
          to={`ministry/${id}/users/1`}
          style={{ color: "#777", textAlign: "center" }}
        >
          <Button type="link" className="darkbtn">
            SHOW ACCOUNTS
          </Button>
        </Link>
      ),
    },
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>
          ministryRequestType
        </p>
      ),
      dataIndex: "ministryRequestType",
      key: "ministryRequestType",
      width: "20%",
      render: (ministryRequestType: any) => {
        return (
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: 0,
            }}
          >
            {ministryRequestType === 1
              ? "Civil ID"
              : ministryRequestType === 2
              ? "Disability ID"
              : "-"}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>CLIENT REQUESTS</p>
      ),
      dataIndex: "id",
      key: "id",
      width: "15%",
      render: (id: any) => (
        <Link
          to={`ministry/${id}/ClientRequest/`}
          style={{ color: "#777", textAlign: "center" }}
        >
          <Button type="link" className="darkbtn">
            SHOW CLIENT REQUESTS
          </Button>
        </Link>
      ),
    },
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>Actions</p>,
      key: "actions",
      dataIndex: "id",
      width: "20%",
      render: (id: any, body: any) => {
        return (
          <Row
            justify="center"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            {/* <Row> */}
            <Popconfirm
              placement="top"
              title={"Are you sure to delete this Ministry"}
              onConfirm={() => confirm(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                ghost
                style={{
                  padding: "0px 8px",
                  margin: "auto 5px",
                  height: "30px",
                }}
              >
                <RiDeleteBin5Fill
                  style={{
                    fontSize: "small",
                    margin: "auto",
                    marginBottom: "15px",
                  }}
                />
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              ghost
              onClick={() => showEditDrawer(id)}
              style={{
                padding: "0px 8px",
                margin: "auto 5px",
                height: "30px",
              }}
            >
              <RiEditFill
                style={{
                  fontSize: "small",
                  margin: "auto",
                  marginBottom: "15px",
                }}
              />
            </Button>
          </Row>
        );
      },
    },
  ];

  const AddHandler = (e: any) => {
    setDisabledSubmit(true);
    let body: Add_Ministry_Req = {
      translations: [],
      attachmentId: 0,
      ministryRequestType: 0,
      isActive: true,
    };
    let objectArabic: Translation = {
      description: e.description_ar,
      name: e.name_ar,
      language: "ar",
    };
    let objectEnglish: Translation = {
      description: e.description_en,
      name: e.name_en,
      language: "en",
    };
    body.ministryRequestType = typeMinistry;
    body.translations.push(objectArabic, objectEnglish);
    body.isActive = true;
    let formData = new FormData();
    formData.append("RefType", 1 + " ");
    formData.append("File", e?.image1?.file);
    UploadAttachment.UploadAttachment(formData).then((res: any) => {
      body.attachmentId = res?.result?.id as number;
      AddNewMinistry.mutateAsync(body);
      setDisabledSubmit(false);
    });
  };

  const editHandler = (e: any) => {
    let body: Edit_Ministry_Req = {
      translations: [],
      attachmentId: 0,
      ministryRequestType: 0,
      isActive: true,
      id: 0,
    };
    let objectArabic: Translation = {
      description: e.description_ar,
      name: e.name_ar,
      language: "ar",
    };
    let objectEnglish: Translation = {
      description: e.description_en,
      name: e.name_en,
      language: "en",
    };
    body.ministryRequestType = typeMinistry;
    body.translations.push(objectArabic, objectEnglish);
    body.isActive = true;
    body.id = idMinistry;
    if (e.attachment.url === GetOneMinistry.data.attachment.url) {
      body.attachmentId = GetOneMinistry.data.attachment.id;
      EditTheMinistry.mutateAsync(body);
    } else {
      let formData = new FormData();
      formData.append("RefType", 1 + " ");
      formData.append("File", e.attachment.file);
      UploadAttachment.UploadAttachment(formData).then((res: any) => {
        body.attachmentId = res.result.id;
        EditTheMinistry.mutateAsync(body);
      });
    }
  };

  useEffect(() => {
    if (totalCount < data?.totalCount || totalCount > data?.totalCount) {
      SetTotalCount(data?.totalCount);
    }
  }, [data, totalCount]);

  function handlePageSizeChange(value: any) {
    setSelectedValue(value);
  }
  const pageSize = selectedValue;

  function handleKeyDown(event: any) {
    if (event.key === "ArrowLeft") {
      setSkipCount(skipCount - pageSize);
    } else if (event.key === "ArrowRight") {
      setSkipCount(skipCount + pageSize);
    }
  }

  function handleChange(value: any) {
    setTypeMinistry(value);
  }

  return (
    <>
      <div className="setting-drwer" onClick={showAddDrawer}>
        <AiOutlineAppstoreAdd />
      </div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24} style={{ width: "100%" }}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col></Col>
                  <Col>Ministries</Col>
                  <Col></Col>
                </Row>
              }
            >
              <GridLoader
                color="#36d7b7"
                loading={isLoading}
                size={10}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignContent: "space-around",
                  flexWrap: "wrap",
                  margin: "20% auto",
                }}
              />
              <div className="table-responsive">
                <Table
                  columns={data ? columns : []}
                  dataSource={data?.items}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
              <Row
                style={{
                  width: "100%",
                  justifyContent: "center",
                  margin: "20px auto",
                }}
              >
                <Row tabIndex={0} onKeyDown={handleKeyDown}>
                  <Col>
                    <Pagination
                      current={skipcount / selectedValue + 1}
                      total={totalCount}
                      pageSize={selectedValue}
                      onChange={(page) => {
                        SetSkipCount((page - 1) * selectedValue);
                      }}
                    />
                  </Col>
                  <Col style={{ marginLeft: "20px" }}>
                    <Select
                      defaultValue={pageSizeOptions[0]}
                      onChange={handlePageSizeChange}
                    >
                      {pageSizeOptions.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <Drawer
        width={isMobile ? 300 : 500}
        title="Add New Ministry"
        open={addVisible}
        onClose={() => setAddVisible(false)}
        placement="right"
      >
        <Form form={form1} name="basic1" onFinish={AddHandler}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"name_ar"}
              name="name_ar"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "The Arabic-Name is Required ",
                },
              ]}
            >
              <Input placeholder="Arabic-Name"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"name_en"}
              name="name_en"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "The English-Name is Required ",
                },
              ]}
            >
              <Input placeholder="English-Name"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"Description-ar"}
              name="description_ar"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "the Arabic_Description is Required ",
                },
              ]}
            >
              <TextArea placeholder="Arabic_Description"></TextArea>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"Description_en"}
              name="description_en"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "The English-Description is Required ",
                },
              ]}
            >
              <TextArea placeholder="English-Description"></TextArea>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"ministryRequestType"}
              name="ministryRequestType"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "The ministryRequestType is Required ",
                },
              ]}
            >
              <Select
                // defaultValue="Civil ID"
                // style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: "1", label: "Civil ID" },
                  { value: "2", label: "Disability ID" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"image"}
              name="image1"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "the Ministry Image is Required ",
                },
              ]}
            >
              <Upload
                listType="picture"
                className="upload-list-inline"
                beforeUpload={() => false}
                maxCount={1}
              >
                <div
                  style={{
                    color: "#0388cc",
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabledSubmit}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Drawer>
      <Drawer
        width={isMobile ? 300 : 500}
        title="Edit The Ministry"
        open={editVisible}
        onClose={() => {
          setEditVisible(false);
        }}
        placement="right"
      >
        <GridLoader
          color="#36d7b7"
          loading={GetOneMinistry.status !== "success" && !GetOneMinistry.data}
          size={10}
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignContent: "space-around",
            flexWrap: "wrap",
            margin: "auto",
            marginTop: "60%",
          }}
        />
        {GetOneMinistry.status === "success" && GetOneMinistry.data && (
          <Form
            form={form2}
            name="basic2"
            autoComplete="off"
            onFinish={editHandler}
            initialValues={{
              ...GetOneMinistry.data,
            }}
          >
            {GetOneMinistry.data?.translations.map((translation: any) => {
              if (translation?.language === "ar") {
                return (
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      label={"name_ar"}
                      name="name_ar"
                      initialValue={translation.name}
                      labelCol={{ span: 24 }}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "The Arabic-Name is Required ",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                );
              }
            })}
            {GetOneMinistry.data?.translations.map((translation: any) => {
              if (translation?.language === "en") {
                return (
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      label={"name_en"}
                      name="name_en"
                      initialValue={translation.name}
                      labelCol={{ span: 24 }}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "The English-Name is Required ",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                );
              }
            })}
            {GetOneMinistry.data?.translations.map((translation: any) => {
              if (translation?.language === "ar") {
                return (
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      label={"Description-ar"}
                      name="description_ar"
                      initialValue={translation.description}
                      labelCol={{ span: 24 }}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "the Arabic_Description is Required ",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </Col>
                );
              }
            })}
            {GetOneMinistry.data?.translations.map((translation: any) => {
              if (translation?.language === "en") {
                return (
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      label={"Description_en"}
                      name="description_en"
                      initialValue={translation.description}
                      labelCol={{ span: 24 }}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "The English-Description is Required ",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </Col>
                );
              }
            })}
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label={"ministryRequestType"}
                name=""
                initialValue={
                  GetOneMinistry.data?.ministryRequestType === 2
                    ? "Disability ID"
                    : GetOneMinistry.data?.ministryRequestType === 1
                    ? "Civil ID"
                    : ""
                }
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "The ministryRequestType is Required ",
                  },
                ]}
              >
                <Select
                  onChange={handleChange}
                  options={[
                    { value: "1", label: "Civil ID" },
                    { value: "2", label: "Disability ID" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label="image"
                name="attachment"
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "the Ministry Image is Required ",
                  },
                ]}
              >
                <Upload
                  listType="picture"
                  className="upload-list-inline"
                  beforeUpload={() => false}
                  maxCount={1}
                  defaultFileList={
                    GetOneMinistry?.data?.attachment
                      ? [
                          {
                            uid: GetOneMinistry?.data?.attachment.id,
                            name: "attachment",
                            url: GetOneMinistry?.data?.attachment.url,
                            thumbUrl: GetOneMinistry?.data?.attachment.url,
                          },
                        ]
                      : []
                  }
                >
                  <div
                    style={{
                      color: "#0388cc",
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Form>
        )}
      </Drawer>
    </>
  );
}

export default Ministries;
