import { Ministries } from "../services/Ministries";

export const GetMinistryById = async (id: any) => {
  const result = await Ministries.GetMinistryById(id);
  return result.result;
};

export const getMinistriesWithPagination = async (
  selectedValue: number,
  Keyword: any,
  skipcount: any
) => {
  const result = await Ministries.getMinistriesWithPagination(
    selectedValue,
    Keyword,
    skipcount
  );
  return result.result;
};

export const getMinistriesWithoutPagination = async () => {
  const result = await Ministries.getMinistriesWithoutPagination();
  return result.result;
};

export const AddMinistry = async (body: any) => {
  const result = await Ministries.AddMinistry(body);
  return result;
};

export const EditMinistry = async (body: any) => {
  const result = await Ministries.EditMinistry(body);
  return result;
};

export const DeleteMinistry = async (id: any) => {
  const result = await Ministries.DeleteMinistry(id);
  return result;
};
