import { useEffect } from "react";
import { Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { logout } from "../actions/auth";
import { useDispatch } from "react-redux";

function Header() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <Col
        span={24}
        md={18}
        className="header-control"
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "100%",
        }}
      >
        <Button
          type="link"
          onClick={() => {
            dispatch<any>(logout());
            history.push("/");
          }}
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <p style={{ margin: "0px" }}>Logout</p>
          <LogoutOutlined
            style={{
              fontSize: "small",
            }}
          />
        </Button>
      </Col>
    </>
  );
}

export default Header;
