import { AxiosRequestConfig } from "axios";
import ApiService from "../utils/api-services";

class ClientRequestsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: `${process.env.REACT_APP_BASE_LOCALY_URL}api`,
      ...config,
    });
  }

  public getClientRequestsWithPagination = async (
    selectedValue: number,
    keyword: any,
    SkipCount: any,
    ministry_id: number,
    FirstDate?: string | null,
    FinalDate?: string | null
  ): Promise<any> => {
    const queryParams: Record<string, any> = {
      MaxResultCount: selectedValue,
      SkipCount,
      Keyword: keyword,
      MinistryId: ministry_id,
      IsForAdmin: true,
    };

    if (FirstDate && FinalDate) {
      queryParams.FirstDate = FirstDate;
      queryParams.FinalDate = FinalDate;
    }

    if (FirstDate && !FinalDate) {
      queryParams.FirstDate = FirstDate;
    }

    if (!FirstDate && FinalDate) {
      queryParams.FinalDate = FinalDate;
    }

    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");

    return await this.get(`/services/app/ClientRequest/GetAll?${queryString}`);
  };

  public getClientRequestsForExport = async (
    keyword: any,
    SkipCount: any,
    ministry_id: number,
    FirstDate: string | null,
    FinalDate?: string | null
  ): Promise<any> => {
    const queryParams: Record<string, any> = {
      MaxResultCount: 1000000000,
      SkipCount,
      Keyword: keyword,
      MinistryId: ministry_id,
      IsForAdmin: true,
      FirstDate: FirstDate,
    };

    if (FinalDate) {
      queryParams.FinalDate = FinalDate;
    }

    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");

    return await this.get(
      `/services/app/ClientRequest/GetClientRequestAsExcelFile?${queryString}`,
      {
        responseType: "arraybuffer",
      }
    );
  };
}

export const ClientRequests = new ClientRequestsService();
