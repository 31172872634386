import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";
import { notification } from "antd";

// export const register =
//   (username: string, email: string, password: string) => (dispatch: any) => {
//     return AuthService.register(username, email, password).then(
//       (response) => {
//         dispatch({
//           type: REGISTER_SUCCESS,
//         });

//         dispatch({
//           type: SET_MESSAGE,
//           payload: response.data.message,
//         });

//         return Promise.resolve();
//       },
//       (error) => {
//         const message =
//           (error.response &&
//             error.response.data &&
//             error.response.data.message) ||
//           error.message ||
//           error.toString();
//         notification.open({
//           message: "Notification Title",
//           description: message,
//           onClick: () => {},
//         });

//         dispatch({
//           type: REGISTER_FAIL,
//         });

//         dispatch({
//           type: SET_MESSAGE,
//           payload: message,
//         });

//         return Promise.reject();
//       }
//     );
//   };

export const login =
  (email: string, password: string, device_id: string) => (dispatch: any) => {
    return AuthService.login(email, password, device_id).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data.result.accessToken },
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        notification.open({
          message: "Error",
          description: "email or password is wrong !!",
          onClick: () => {},
        });

        dispatch({
          type: LOGIN_FAIL,
        });

        return Promise.reject();
      }
    );
  };

export const logout = () => (dispatch: any) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
