import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  Translation,
  Add_Sector_Req,
  Edit_Sector_Req,
} from "../enum/sector-enum";
import { GetMinistryById } from "../react-query-services/Ministries";
import {
  GetSectorById,
  getSectorsWithPagination,
  AddSector,
  EditSector,
  DeleteSector,
} from "../react-query-services/Sectors";
import {
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
  Input,
  Drawer,
  notification,
  Popconfirm,
  Pagination,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { RiDeleteBin5Fill, RiEditFill } from "react-icons/ri";
import { IoReturnUpBackOutline } from "react-icons/io5";
import GridLoader from "react-spinners/GridLoader";
import { useMediaQuery } from "react-responsive";
import "../assets/styles/Drawer.css";
import "../assets/styles/Dropdown.css";

interface Iparam {
  id: string;
}

function Sectors(props: any) {
  let id: Iparam = useParams();
  const { TextArea } = Input;
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [idSector, setidSector] = useState<number>(0);
  const [skipcount, SetSkipCount] = useState<number>(0);
  const [totalCount, SetTotalCount] = useState<any>(1);
  const [selectedValue, setSelectedValue] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const pageSizeOptions = ["10", "20", "30"];
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const GetOneMinistry = useQuery({
    queryKey: ["GetMinistryById", id.id],
    queryFn: () => GetMinistryById(id.id),
  });

  const GetOneSector = useQuery({
    queryKey: ["GetSectorById", idSector],
    queryFn: () => GetSectorById(idSector),
    enabled: idSector !== 0,
  });

  useEffect(() => {
    if (idSector !== 0) {
      form2.resetFields();
      GetOneSector.refetch();
    }
  }, [idSector, editVisible]);

  const { isLoading, isError, error, data, isFetching, refetch } = useQuery({
    queryKey: [
      "getSectorsWithPagination",
      selectedValue,
      props.Search,
      skipcount,
      id.id,
    ],
    queryFn: () =>
      getSectorsWithPagination(selectedValue, props.Search, skipcount, +id.id),
    enabled: !!id.id,
  });

  const AddNewSector = useMutation({
    mutationKey: ["AddNewSector"],
    mutationFn: AddSector,
    onSuccess: (res: any) => {
      if (res.error) {
        notification.error({
          message: res?.error?.message,
        });
        return;
      }
      notification.success({
        message: "The Sector Added Successfuly",
      });
      refetch();
      form1.resetFields();
      setAddVisible(false);
    },
    onError: (res: any) => {
      notification.error({
        message: res?.error?.message,
      });
    },
  });

  const EditTheSector = useMutation({
    mutationKey: ["EditSector"],
    mutationFn: EditSector,
    onSuccess: (res: any) => {
      if (res.error) {
        notification.error({
          message: res?.error?.message,
        });
        return;
      }
      notification.success({
        message: "The Sector Updated Successfuly",
      });
      refetch();
      form2.resetFields();
      setEditVisible(false);
    },
    onError: (res: any) => {
      notification.error({
        message: res?.error?.message,
      });
    },
  });

  const DeletedHandler = (id: any) => {
    DeleteSector(id).then((res) => {
      if (res?.result == null && res.error == null) {
        notification.success({
          message: " The Sector Deleted Successfully",
        });
        refetch();
      }
      if (res?.result == null && res.error != null) {
        notification.error({
          message: res?.error?.message,
        });
      }
    });
  };

  const confirm = (id: any) => {
    DeletedHandler(id);
  };

  const showAddDrawer = () => setAddVisible(true);
  const showEditDrawer = (id: any) => {
    setEditVisible(true);
    setidSector(id);
  };

  const columns = [
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>Name</p>,
      dataIndex: "name",
      key: "name",
      width: "22%",
      render: (name: any, id: any) => {
        return (
          <p
            style={{
              maxWidth: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {name + ""}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>Description</p>
      ),
      dataIndex: "description",
      key: "description",
      width: "20%",
      render: (description: any) => {
        return (
          <p
            style={{
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {description ? description : "There Is No Description"}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ marginBottom: 0, textAlign: "center" }}>Departments</p>
      ),
      dataIndex: "id",
      key: "id",
      width: "20%",
      render: (id: any) => (
        <Link
          to={`${id}/department/`}
          style={{ color: "#777", textAlign: "center" }}
        >
          <Button type="link" className="darkbtn">
            SHOW DEPARTMENTS
          </Button>
        </Link>
      ),
    },
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>Leaders</p>,
      dataIndex: "id",
      key: "id",
      width: "20%",
      render: (id: any) => (
        <Link
          to={`${id}/users/2`}
          style={{ color: "#777", textAlign: "center" }}
        >
          <Button type="link" className="darkbtn">
            SHOW ACCOUNTS
          </Button>
        </Link>
      ),
    },
    {
      title: <p style={{ marginBottom: 0, textAlign: "center" }}>Actions</p>,
      key: "actions",
      dataIndex: "id",
      width: "22%",
      render: (id: any, body: any) => {
        return (
          <Row
            justify="center"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <Popconfirm
              placement="top"
              title={"Are you sure to delete this Sector?"}
              onConfirm={() => confirm(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                ghost
                style={{
                  padding: "0px 8px",
                  margin: "auto 5px",
                  height: "30px",
                }}
              >
                <RiDeleteBin5Fill
                  style={{
                    fontSize: "small",
                    margin: "auto",
                    marginBottom: "15px",
                  }}
                />
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              ghost
              onClick={() => showEditDrawer(id)}
              style={{
                padding: "0px 8px",
                margin: "auto 5px",
                height: "30px",
              }}
            >
              <RiEditFill
                style={{
                  fontSize: "small",
                  margin: "auto",
                  marginBottom: "15px",
                }}
              />
            </Button>
          </Row>
        );
      },
    },
  ];

  const AddHandler = (e: any) => {
    let body: Add_Sector_Req = {
      translations: [],
      ministryId: 0,
      isActive: true,
    };
    let objectArabic: Translation = {
      description: e.description_ar,
      name: e.name_ar,
      language: "ar",
    };
    let objectEnglish: Translation = {
      description: e.description_en,
      name: e.name_en,
      language: "en",
    };
    body.translations.push(objectArabic, objectEnglish);
    body.ministryId = +id.id;
    body.isActive = true;
    AddNewSector.mutateAsync(body);
  };

  const editHandler = (e: any) => {
    let body: Edit_Sector_Req = {
      translations: [],
      ministryId: 0,
      isActive: true,
      id: 0,
    };
    let objectArabic: Translation = {
      description: e.description_ar,
      name: e.name_ar,
      language: "ar",
    };
    let objectEnglish: Translation = {
      description: e.description_en,
      name: e.name_en,
      language: "en",
    };
    body.translations.push(objectArabic, objectEnglish);
    body.isActive = true;
    body.id = idSector;
    body.ministryId = GetOneSector.data.ministry.id;
    EditTheSector.mutateAsync(body);
  };

  useEffect(() => {
    if (totalCount < data?.totalCount || totalCount > data?.totalCount) {
      SetTotalCount(data?.totalCount);
    }
  }, [data, totalCount]);

  function handlePageSizeChange(value: any) {
    setSelectedValue(value);
  }
  const pageSize = selectedValue;

  function handleKeyDown(event: any) {
    if (event.key === "ArrowLeft") {
      setSkipCount(skipCount - pageSize);
    } else if (event.key === "ArrowRight") {
      setSkipCount(skipCount + pageSize);
    }
  }

  return (
    <>
      <div className="setting-drwer" onClick={showAddDrawer}>
        <AiOutlineAppstoreAdd />
      </div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24} style={{ width: "100%" }}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col>
                    <Link to="/ministries">
                      <IoReturnUpBackOutline
                        style={{
                          marginRight: 8,
                          cursor: "pointer",
                          color: "rgba(0, 0, 0, 0.65)",
                          fontSize: "x-large",
                        }}
                      />
                    </Link>
                  </Col>
                  {!isLoading && (
                    <Col>Sectors of : {GetOneMinistry?.data?.name}</Col>
                  )}
                  <Col></Col>
                </Row>
              }
            >
              <GridLoader
                color="#36d7b7"
                loading={isLoading}
                size={10}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignContent: "space-around",
                  flexWrap: "wrap",
                  margin: "20% auto",
                }}
              />
              <div className="table-responsive">
                <Table
                  columns={data ? columns : []}
                  dataSource={data?.items}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
              <Row
                style={{
                  width: "100%",
                  justifyContent: "center",
                  margin: "20px auto",
                }}
              >
                <Row tabIndex={0} onKeyDown={handleKeyDown}>
                  <Col>
                    <Pagination
                      current={skipcount / selectedValue + 1}
                      total={totalCount}
                      pageSize={selectedValue}
                      onChange={(page) => {
                        SetSkipCount((page - 1) * selectedValue);
                      }}
                    />
                  </Col>
                  <Col style={{ marginLeft: "20px" }}>
                    <Select
                      defaultValue={pageSizeOptions[0]}
                      onChange={handlePageSizeChange}
                    >
                      {pageSizeOptions.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <Drawer
        width={isMobile ? 300 : 500}
        title="Add New Sector"
        open={addVisible}
        onClose={() => setAddVisible(false)}
        placement="right"
      >
        <Form form={form1} name="basic1" onFinish={AddHandler}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"name_ar"}
              name="name_ar"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "The Arabic-Name is Required ",
                },
              ]}
            >
              <Input placeholder="Arabic-Name"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"name_en"}
              name="name_en"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "The English-Name is Required ",
                },
              ]}
            >
              <Input placeholder="English-Name"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"Description-ar"}
              name="description_ar"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "the Arabic_Description is Required ",
                },
              ]}
            >
              <TextArea placeholder="Arabic_Description"></TextArea>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label={"Description_en"}
              name="description_en"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "The English-Description is Required ",
                },
              ]}
            >
              <TextArea placeholder="English-Description"></TextArea>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Drawer>
      <Drawer
        width={isMobile ? 300 : 500}
        title="Edit The Sector"
        open={editVisible}
        onClose={() => {
          setEditVisible(false);
        }}
        placement="right"
      >
        <GridLoader
          color="#36d7b7"
          loading={GetOneSector.status !== "success" && !GetOneSector.data}
          size={10}
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignContent: "space-around",
            flexWrap: "wrap",
            margin: "auto",
            marginTop: "60%",
          }}
        />
        {GetOneSector.status === "success" && GetOneSector.data && (
          <Form
            form={form2}
            name="basic2"
            autoComplete="off"
            onFinish={editHandler}
            initialValues={{
              ...GetOneSector.data,
            }}
          >
            {GetOneSector.data?.translations.map((translation: any) => {
              if (translation?.language === "ar") {
                return (
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      label={"name_ar"}
                      name="name_ar"
                      initialValue={translation.name}
                      labelCol={{ span: 24 }}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "The Arabic-Name is Required ",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                );
              }
            })}
            {GetOneSector.data?.translations.map((translation: any) => {
              if (translation?.language === "en") {
                return (
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      label={"name_en"}
                      name="name_en"
                      initialValue={translation.name}
                      labelCol={{ span: 24 }}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "The English-Name is Required ",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                );
              }
            })}
            {GetOneSector.data?.translations.map((translation: any) => {
              if (translation?.language === "ar") {
                return (
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      label={"Description-ar"}
                      name="description_ar"
                      initialValue={translation.description}
                      labelCol={{ span: 24 }}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "the Arabic_Description is Required ",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </Col>
                );
              }
            })}
            {GetOneSector.data?.translations.map((translation: any) => {
              if (translation?.language === "en") {
                return (
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      label={"Description_en"}
                      name="description_en"
                      initialValue={translation.description}
                      labelCol={{ span: 24 }}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "The English-Description is Required ",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </Col>
                );
              }
            })}
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Form>
        )}
      </Drawer>
    </>
  );
}

export default Sectors;
