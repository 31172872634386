import { useEffect, useState } from "react";
import Dashboard from "./pages/Dashboard";
import Ministries from "./pages/Ministries";
import Sectors from "./pages/Sectors";
import Departments from "./pages/Departments";
import Users from "./pages/Users";
import SignUp from "./pages/SignUp";
import Main from "./components/Main";
import { Switch, Route, Redirect } from "react-router-dom";
import { logout } from "./actions/auth";
import { useDispatch } from "react-redux";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import eventManager, { EVENT_UNAUTHORIZED } from "./utils/event-manager";
import { notification } from "antd";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ClientRequest from "./pages/ClientRequest";

function App() {
  const [Search, setSearch] = useState<any>("");
  const queryClient = new QueryClient();
  notification.config({
    placement: "topLeft",
    top: 10,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    eventManager.on(EVENT_UNAUTHORIZED, () => {
      dispatch<any>(logout());
    });
  }, []);

  const changeKeyword = (search: any) => {
    setSearch(search);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Switch>
          <Route path="/login" exact component={SignUp} />
          <Main>
            <Input
              className="header-search"
              placeholder="Type here..."
              prefix={<SearchOutlined />}
              onChange={(search: any) => {
                changeKeyword(search.target.value);
              }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "fit-content",
                marginBottom: "15px",
                marginTop: "-62px",
              }}
            />

            {/* <Route exact path="/dashboard">
              <Dashboard />
            </Route> */}

            <Route exact path="/">
              <Ministries Search={Search} />
            </Route>

            <Route exact path="/ministries">
              <Ministries Search={Search} />
            </Route>

            <Route exact path="/ministry/:id/sector">
              <Sectors Search={Search} />
            </Route>

            <Route exact path="/ministry/:id/ClientRequest">
              <ClientRequest Search={Search} />
            </Route>

            <Route
              exact
              path="/ministry/:id/sector/:idSector/department"
              component={Departments}
            >
              <Departments Search={Search} />
            </Route>

            {/* users of ministry */}
            <Route exact path="/ministry/:id/users/:userType">
              <Users Search={Search} />
            </Route>

            {/* users of sector */}
            <Route exact path="/ministry/:id/sector/:idSector/users/:userType">
              <Users Search={Search} />
            </Route>

            {/* users of department */}
            <Route
              exact
              path="/ministry/:id/sector/:idSector/department/:idDepartment/users/:userType"
            >
              <Users Search={Search} />
            </Route>
          </Main>
        </Switch>
      </div>
    </QueryClientProvider>
  );
}

export default App;
