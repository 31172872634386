import { ClientRequests } from "../services/ClientRequest";

export const getClientRequestsWithPagination = async (
  selectedValue: number,
  Keyword: any,
  skipcount: any,
  ministry_id: number,
  FirstDate?: string | null,
  FinalDate?: string | null
) => {
  const result = await ClientRequests.getClientRequestsWithPagination(
    selectedValue,
    Keyword,
    skipcount,
    ministry_id,
    FirstDate,
    FinalDate
  );
  return result.result;
};

export const getClientRequestsForExport = async (
  Keyword: any,
  skipcount: any,
  ministry_id: number,
  FirstDate: string | null,
  FinalDate?: string | null
) => {
  const result = await ClientRequests.getClientRequestsForExport(
    Keyword,
    skipcount,
    ministry_id,
    FirstDate,
    FinalDate
  );
  return result;
};
