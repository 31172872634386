import { AxiosRequestConfig } from "axios";
import ApiService from "../utils/api-services";

class CategoryService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    // super({
    //   baseURL: `https://ministrieapidev.metasignapp.com/api/`,
    //   ...config,
    // });
    super({
      baseURL: `https://ministryapi.metasignapp.com/api/`,
      ...config,
    });
  }

  public UploadAttachment = async (body: any): Promise<any> => {
    return await this.post("/services/app/Attachment/Upload", body);
  };
}

export const UploadAttachment = new CategoryService();
